<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Menu Item</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Type (*)</span>
            <v-select
              label="name"
              :options="typeOptions"
              :reduce="(type) => type.value"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.type"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("type")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Menu Parent</span>
            <v-select
              label="name"
              :options="menuParentOptions"
              :reduce="(parent) => parent.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.menu_parent_id"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              label="Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-link"
              icon-no-border
              label="Menu URL"
              placeholder="/blablabla"
              v-model="payload.url"
            />
          </vs-col>
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Menu Order"
              placeholder=""
              v-model="payload.order"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3" v-if="payload.type == 'admin'">
            <p class="mb-2 block text-sm">
              Menu Icon (See at https://vue-feather-icons.egoist.sh) (*)
            </p>
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-image"
              icon-no-border
              placeholder="HomeIcon"
              v-model="payload.icon"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("url")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col
        vs-w="6"
        :class="['pl-8', payload.type == 'admin' ? 'hidden' : '']"
      >
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="200" height="200" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("icon")
        }}</span>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleMenuItemManagement from "@/store/menu-item-management/moduleMenuItemManagement.js";

export default {
  name: "MenuItemAdd",

  components: {
    "v-select": vSelect,
  },

  data: () => ({
    payload: {
      type: "sidebar",
      name: "",
      url: "",
      icon: "",
      menu_parent_id: 0,
    },

    url: null,

    typeOptions: [
      {
        name: "Sidebar",
        value: "sidebar",
      },
      {
        name: "Navbar",
        value: "navbar",
      },
      {
        name: "Admin Menu",
        value: "admin",
      },
    ],

    menuParentOptions: [
      {
        id: 0,
        name: "None",
      },
    ],
  }),

  computed: {
    url() {
      return "https://via.placeholder.com/200";
    },
  },

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.icon = file;
    },

    removeImage() {
      this.url = "https://via.placeholder.com/200";
      this.payload.icon = "";
    },

    getMenuItems() {
      axios.get("menu-item").then(({ data: res }) => {
        const menuItems = res.data
          .filter((menu) => menu.menu_parent_id === 0 && menu.url === "#")
          .map((menu) => {
            return {
              id: menu.id,
              name: menu.name,
            };
          });

        this.menuParentOptions.push(...menuItems);
      });
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("type", this.payload.type);
      formData.set("order", this.payload.order);
      if (this.payload.url !== "") {
        formData.set("url", this.payload.url);
      }
      if (this.payload.icon !== "") {
        formData.set("icon", this.payload.icon);
      }
      if (this.payload.menu_parent_id !== 0) {
        formData.set("menu_parent_id", this.payload.menu_parent_id);
      }

      this.$store
        .dispatch("menuItemManagement/storeMenuItem", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.go(-1);
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  created() {
    if (!moduleMenuItemManagement.isRegistered) {
      this.$store.registerModule(
        "menuItemManagement",
        moduleMenuItemManagement
      );
      moduleMenuItemManagement.isRegistered = true;
    }

    this.getMenuItems();
  },
};
</script>

<style></style>
