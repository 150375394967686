import axios from '@/axios.js'

export default {
  fetchMenuItems ({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios.get(`/menu-item?type=${type}`)
        .then(({data: response}) => {
          commit('SET_MENU_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchMenuItem (context, menuItemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/menu-item/${menuItemId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeMenuItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/menu-item', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateMenuItem (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/menu-item/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyMenuItem ({ commit }, menuItemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`menu-item/${menuItemId}`)
        .then((response) => {
          commit('REMOVE_RECORD', menuItemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
